<template>
  <div class="coins_box">
    <div class="w1120">
      <div @click="$router.go(-1)" class="back">
        <i class="icon iconfont icon-xiadan-fanhui back_icon"></i>
        <span class="back_text">Go Back</span>
      </div>
      <div class="coins">Your Coins</div>
      <div class="count">
        <i class="iconfont icon-meiyuan"></i>
        <span>{{ integrate }}</span>
      </div>
      <div class="des">The coins can be used to deduct a portion of the order amount or redeem coupons ({{ coin }} Coins = USD {{ usd }})</div>
      <div class="title">Activity log</div>
      <div style="background: #fff">
        <el-table :data="tableData" style="width: 100%" :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)', 'font-weight': 600 }" v-loading="showLoading">
          <el-table-column prop="memo" label="Coins Details" width="570" align="center">
            <template #default="{ row }">
              {{ formatDetail(row.memo, row.order_number) }}
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="Time" width="180" align="center">
            <template #default="{ row }">
              {{ formatDate(row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="Coins" width="150" align="center">
            <template #default="{ row }">
              {{ formatAmount(row.type, row.amount) }}
            </template>
          </el-table-column>
          <el-table-column prop="balance" label="Remaining coins" align="center">
            <template #default="{ row }">
              {{ formatNumber(row.balance) }}
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="pagina">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pageMeta.total"
            :page-size="pageMeta.per_page"
            @current-change="handleCurrentChange"
            :hide-on-single-page="true"
          ></el-pagination>
        </div>
      </div>
    </div>
    <copyright-info style="margin-top: auto;"/>
  </div>
</template>

<script setup>
import { ref, computed, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import CopyrightInfo from '@/components/copyrightInfo.vue'
import { getUserIntegrateList } from '@/api/coins'

const $router = useRouter()
const $store = useStore()

const integrate = computed(() => $store.state.coins.coinsInfo.integrate)
const coin = computed(() => $store.state.coins.coinsInfo.coin)
const usd = computed(() => $store.state.coins.coinsInfo.usd)

const showLoading = ref(false)
const tableData = ref([])
const pageMeta = reactive({})
const page = ref(1)

const integrateList = () => {
  showLoading.value = true
  getUserIntegrateList(page.value).then(({ data: res }) => {
    const { data, meta } = res
    tableData.value = data
    pageMeta.total = meta.total
    pageMeta.per_page = meta.per_page
    showLoading.value = false
  }).catch(e => e)
}

const initData = () => {
  coin.value === null && $store.dispatch('coins/coinsAction')
}

onMounted(() => {
  integrateList()
  initData()
})

const handleCurrentChange = (e) => {
  page.value = e
  integrateList()
}

// 格式化 coins Details
const formatDetail = (memo, number) => {
  if (!!number) {
    memo = memo + ', order number: ' + number
  }
  return memo
}

// 格式化 Time
let formatDate = (time) => {
  const date = new Date(time);
  let dateString = date.toDateString();
  dateString = dateString.split(" ").slice(1).join(" ");
  const timeString = time.split(" ")[1].split(":").splice(0, 2).join(":")
  return dateString + " " + timeString;
};

// 格式化 amount
const formatAmount = (type, number) => {
  if (type === 1 || type === 2) {
    return '- ' + formatNumber(number)
  } else {
    return '+ ' + formatNumber(number)
  }
}

// 格式化 balance
const formatNumber = (num) => {
  return num.split('.')[0]
}
</script>

<style scoped lang="scss">
@import "@/views/myAccount/account.scss";
.coins_box {
  width: 100%;
  display: flex;
  min-height: calc(100vh - 65px);
  flex-direction: column;
  .coins {
    margin-top: 26px;
    font-size: 36px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 50px;
  }
  .count {
    margin-top:20px;
    height: 60px;
    line-height: 60px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    i {
      font-size: 36px;
      color: #FFC400;
      margin-right: 12px;
    }
  }
  .des {
    margin: 10px 0 30px 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    line-height: 1.7;
  }
  .title {
    margin-bottom: 20px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
  }
}
.w1120 {
  padding: 50px 0 40px 0;
  width: 1120px ;
  margin: 0 auto;
}
.back {
  width: 105px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  .back_icon {
    margin-right: 10px;
    font-size: 18px;
    font-weight: 600;
    vertical-align: top;
    color: rgba(0, 0, 0, 0.5);
  }
  .back_text {
    line-height: 33px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
  }
}
.pagina {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 30px;
}
.pagination {
  padding: 30px 0;
  background: #fff;
  text-align: center;
}
</style>
