<script setup>
  const props = defineProps({
    mode: {
      type: String, // white or gray
      default: 'gray'
    },
    height: {
      type: String,
      default: '70px'
    }
  })

 let timestamp = Date.parse(new Date());
 
let date = new Date(timestamp);
 
//获取年份  
 
let thieYear = date.getFullYear();


</script>

<template>
  <div :class="['statement', 'statement-' + mode]">
    © {{thieYear}} 99diy, powered by Aantage Sandard Limited, All rights reserved.
  </div>
</template>

<style scoped lang="scss">
  .statement {
    width: 100%;
    height: v-bind(height);
    line-height: v-bind(height);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
    user-select: none;
    &.statement-gray {
      background: #f7f7f7;
    }
    &.statement-white {
      background: #fff;
    }
  }
</style>
